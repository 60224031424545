'use strict'

export const validateGroup = (index, values) => {

    const parseValues = (values) => {
        for (let key of Object.keys(values)) {
            values[key] = parseInt((values[key] === null || !values[key]) ? 0 : values[key]);
        }
        return values;
    }

    values = parseValues(values);

    let errors = {};

    switch (index) {
        // I Datos Unidad Familiar
        case 0:
            const totalPersonas = values['G10'] + values['G14'] + values['G15'] + values['G16'];
            const sumaTrabajadores = values['G21'] + values['I24'] + values['I25'] + values['G26'] + values['G27'] + values['G28'];

            if(!values['G10']){
                errors['G10'] = 'Este campo es obligatorio';
            }

            if(values['I12'] === 0){
                errors['I12'] = 'Necesitamos saber tu edad';
            }

            if(values['G10'] === 2){
                if(values['I13'] === 0){
                    errors['I13'] = 'Necesitamos saber la edad de tu pareja';
                }
                if(values['I13'] < 16){
                    errors['I13'] = 'Este valor es demasiado bajo para este campo';
                }
                if(values['I13'] > 120){
                    errors['I13'] = 'Este valor es demasiado alto para este campo';
                }
            }

            if(values['I12'] < 16){
                errors['I12'] = 'Este valor es demasiado bajo para este campo';
            }

            if(values['I12'] > 120){
                errors['I12'] = 'Este valor es demasiado alto para este campo';
            }

            if(totalPersonas !== sumaTrabajadores){
                errors['G21'] = 'La suma de personas de tu hogar no coincide con el número de personas trabajadoras/pensionistas/otras';
            }

            break;
        case 1:
            //II Ingresos Totales y capacidad de ahorro

            const sumaIngresos = values['G33'] + values['G34'] + values['G35'];
            if(sumaIngresos <= 0){
                errors['G33'] = 'Introduce almenos un tipo de ingreso';
                errors['G34'] = 'Introduce almenos un tipo de ingreso';
                errors['G35'] = 'Introduce almenos un tipo de ingreso';
            }
            if(!values['G38']){
                errors['G38'] = "Este campo es obligatorio";
            }

            break;

        case 8:
            // IX Deudas
            if(!values['G142']){
                errors['G142'] = 'Elige una opción';
            }
            if(!values['G155']){
                errors['G155'] = 'Elige una opción';
            }

    }

    return errors;


}