import {React, useState} from 'react';

const Pregunta = (props) => {
    const {id, cell, label, default_value, input_type, input_options_json, input_attributes_json, description  } = props.pregunta;

    const attributes = input_attributes_json ? JSON.parse(input_attributes_json) : {};
    const options = input_options_json ? JSON.parse(input_options_json): {};

    const buildInput = (cell, input_type) => {
        switch (input_type) {
            case 'select':
                return (<>
                    <select
                    name={cell}
                    id={'input-' + cell}
                        className={`${attributes.class ? attributes.class.join(" ") : ''} ${props.errors[cell] ? 'is-invalid' : ''}`}
                        onChange={(e) => props.onInputChange(cell, e.target.value)}
                        defaultValue={props.value}
                    >
                        <option hidden>Elige una opción</option>
                    {options && Object.keys(options).map(key => {
                        return <option key={key} value={key}>{options[key]}</option>
                    })}
                </select>{props.errors[cell] && <div className="invalid-feedback">{props.errors[cell]}</div>}</>);
            case 'number':
                return (
                    <div className={attributes.class.includes('is-currency') ? 'input-group' : ''}>
                        <input
                        type="number"
                        min="0"
                        max={attributes.max ? attributes.max : ''}
                        maxLength="200"
                        step="1"
                        name={cell}
                        id={'input-' + cell}
                        className={`${attributes.class ? attributes.class.join(" ") : ''} ${props.errors[cell] ? 'is-invalid' : ''}`}
                        onChange={(e) => props.onInputChange(cell, e.target.value)}
                        value={props.value}
                        />
                        {attributes.class.includes('is-currency') && <div className="input-group-append"><div className="input-group-text">€</div></div> }
                        {props.errors[cell] && <div className="invalid-feedback">{props.errors[cell]}</div>}
                    </div>);
            case 'checkbox':
                return (
                <div className="form-check">
                    <input
                        className={`form-check-input ${attributes.class ? attributes.class.join(" ") : ''} ${props.errors[cell] ? 'is-invalid' : ''}`}
                        type="checkbox"
                        value="1"
                        id={'input-' + cell}
                        checked={props.value == 1}
                        onChange={(e) => props.onInputChange(cell, e.target.checked ? 1 : 0)}
                    />
                    <label className="form-check-label" htmlFor={'input-' + cell}>
                        {label}
                    </label>
                    {props.errors[cell] && <div className="invalid-feedback">{props.errors[cell]}</div>}
                </div>
            );
        }
    }

    return (
        <div className={`form-group form-group-${cell}`} style={{width: attributes.width ? attributes.width : '100%'}}>
            {description && <h3>{description}</h3>}
            {input_type !== 'checkbox' && <label htmlFor={'input-' + cell}>{label}</label>}
            {buildInput(cell, input_type, input_attributes_json)}
        </div>
    )
}

export default Pregunta;