import {React, useState} from 'react';
import FeedbackFunctions from "../functions";

const Feedback = (props) => {

    const functions = new FeedbackFunctions(props.values);

    // Recomendación 1. Control de la situación

    // 1 Más de la mitad de los hogares españoles tiene algún tipo de deuda, así que no hay problema por tener deudas, sólo hay que saber gestionarlas bien.
    const output1TieneDeuda = functions.output1TieneDeuda();
    // 2 Un primer indicador positivo es que tu nivel de deuda (incluida hipoteca) no supera el 40% recomendado sobre los ingresos netos
    const output2NivelDeuda = functions.output2NivelDeuda();
    // 3 Tu nivel de endeudamiento (sin tener en cuenta hipoteca) es adecuado al nivel de ingresos, además estás utilizando fuentes de financiación apropiadas
    const output3NivelEndeudamiento = functions.output3NivelEndeudamiento();
    // 4 El nivel de ingresos del hogar es muy adecuado
    const output4NivelIngresosHogar = functions.output4NivelIngresosHogar();

    //Recomendación 2. Educación financiera

    // 5 "Ojo, dedicas un " &Datos!D405& " de gasto dedicado a la vivienda mientras que el Banco de España recomienda que no se supere el 30%"
    const output5GastoVivienda = functions.output5GastoVivienda();
    // 6 Según tus respuestas se detecta cierto esfuerzo para ahorrar, sería importante controlar esas cuantías y que fueran de al menos el 10% de tus ingresos netos
    const output6EsfuerzoParaAhorrar = functions.output6EsfuerzoParaAhorrar();
    // 7 ="En tu hogar se ahorra un " &Datos!I389&", pero el Banco de España recomienda que sea al menos el 10% del ingreso neto"
    const output7NivelDeAhorro = functions.output7NivelDeAhorro();
    // 8 Tu nivel de ahorro es adecuado y coincide con tu percepción sobre tu capacidad de ahorro, ¡enhorabuena!
    const output8NivelAhorroAdecuado = functions.output8NivelAhorroAdecuado();
    // 9 Tu percepción del ahorro mensual no corresponde con tu capacidad de ahorro real
    const output9PercepcionAhorro = functions.output9PercepcionAhorro();
    // 10 Sería altamente recomendable conocer mejor los gastos fijos en vivienda (hipoteca/alquiler, gastos de comunidad, seguro, IBI)
    const output10RecomendacionConocerGastosVivienda = functions.output10RecomendacionConocerGastosVivienda();
    // 11 Sería recomendable controlar tus gastos (suministros, transporte, otros gastos…)
    const output11RecomendacionConocerGastosOtros = output10RecomendacionConocerGastosVivienda ? functions.output11RecomendacionConocerGastosOtros(output10RecomendacionConocerGastosVivienda.ratioOtrosGastos, output10RecomendacionConocerGastosVivienda.ratioVariable) : null;

    // Recomendación 2. Educación financiera

    // 12 Existen indicios de que tienes una percepción más optimista  del nivel de endeudamiento del hogar que la real
    const output12IndiciosPercepcionOptimista = functions.output12IndiciosPercepcionOptimista();
    // 13 Muy bien, es positivo documentarse en varias fuentes de información sobre las alternativas de financiación
    const output13DocumentarseFinanciacion = functions.output13DocumentarseFinanciacion();
    // 14 Considera consultar condiciones de financiación en entidades financieras (oficinas/online)
    const output14ConsideraConsultarCondidicones = functions.output14ConsideraConsultarCondidicones();
    // 15 Es fundamental conocer el coste de la deuda para una apropiada gestión del endeudamiento de tu hogar
    const output15ConocerCosteDeuda = functions.output15ConocerCosteDeuda();

    // Recomendación 3. Posibles estrategias

    // 16 Enhorabuena, controlas bien la situación de gastos de tu hogar y buscas de manera adecuada los métodos de financiación conociendo su coste
    const output16ControlasBienSituacionGastos = output1TieneDeuda && output3NivelEndeudamiento && output14ConsideraConsultarCondidicones && output15ConocerCosteDeuda ? functions.output16ControlasBienSituacionGastos(output3NivelEndeudamiento.score, output3NivelEndeudamiento.score) : null;
    // 17 ¿Por qué no comenzar ya? Algunos tutoriales y dónde aprender más sobre el coste de la deuda
    const output17PorqueNoComenzarYa = output15ConocerCosteDeuda ? functions.output17PorqueNoComenzarYa(): null;
    // 18 Quizás sería adecuado valorar otras alternativas de financiación antes de aceptar las condiciones ofrecidas en el momento de la compra
    const output18OtrasAlternativasFinanciacion = output3NivelEndeudamiento ? functions.output18OtrasAlternativasFinanciacion(output2NivelDeuda.score, output3NivelEndeudamiento) : null;
    // 19 En caso de situación complicada se recomienda revisar los gastos discrecionales. Tus gastos suponen un " & Datos!I391 & " de tus ingresos. Puedes valorar recortar en "
    const output19RevisarGastosDiscrecionales = functions.output19RevisarGastosDiscrecionales(output2NivelDeuda.score);


    const BuildMessage = (props) => {
        const {classes, text} = props.message;
        return <div className={`alert  ${classes ? 'alert-' + classes : 'alert-info'}`}>{text}</div>
    }

    return (
        <div className="card shadow">
            <div className="card-header">
                <h2 className="card-title m-0 p-0 text-center">Recomendaciones</h2>
            </div>
            <div className="card-body">
                {output1TieneDeuda && <BuildMessage message={output1TieneDeuda} />}
                {output2NivelDeuda && <BuildMessage message={output2NivelDeuda} />}
                {output3NivelEndeudamiento && <BuildMessage message={output3NivelEndeudamiento} />}
                {output4NivelIngresosHogar && <BuildMessage message={output4NivelIngresosHogar} />}
                <h3>Recomendación 1. Control de la situación</h3>
                {output5GastoVivienda && <BuildMessage message={output5GastoVivienda} />}
                {output6EsfuerzoParaAhorrar && <BuildMessage message={output6EsfuerzoParaAhorrar} />}
                {output7NivelDeAhorro && <BuildMessage message={output7NivelDeAhorro} />}
                {output8NivelAhorroAdecuado && <BuildMessage message={output8NivelAhorroAdecuado} />}
                {output9PercepcionAhorro && <BuildMessage message={output9PercepcionAhorro} />}
                {output10RecomendacionConocerGastosVivienda && <BuildMessage message={output10RecomendacionConocerGastosVivienda} />}
                {output11RecomendacionConocerGastosOtros && <BuildMessage message={output11RecomendacionConocerGastosOtros}/>}
                <h3>Recomendación 2. Educación financiera</h3>
                {output12IndiciosPercepcionOptimista && <BuildMessage message={output12IndiciosPercepcionOptimista} />}
                {output13DocumentarseFinanciacion && <BuildMessage message={output13DocumentarseFinanciacion} />}
                {output14ConsideraConsultarCondidicones && <BuildMessage message={output14ConsideraConsultarCondidicones} />}
                {output15ConocerCosteDeuda && <BuildMessage message={output15ConocerCosteDeuda} />}
                <h3>Recomendación 3. Posibles estrategias</h3>
                {output16ControlasBienSituacionGastos && <BuildMessage message={output16ControlasBienSituacionGastos} />}
                {output17PorqueNoComenzarYa && <BuildMessage message={output17PorqueNoComenzarYa} />}
                {output18OtrasAlternativasFinanciacion && <BuildMessage message={output18OtrasAlternativasFinanciacion} />}
                {output19RevisarGastosDiscrecionales && <BuildMessage message={output19RevisarGastosDiscrecionales} />}
            </div>
        </div>
    )

}

export default Feedback;