import {React, useState} from 'react';
import Pregunta from "./Pregunta";

const Grupo = (props) => {

    const cuestionario = props.cuestionario;

    const checkConditions = (conditions_json, state) => {
        if(!conditions_json){
            return true;
        }
        const conditions = JSON.parse(conditions_json);
        for(let i = 0; i < conditions.length; i++){
            if(conditions[i][1] === '='){
                return parseInt(state[conditions[i][0]]) === parseInt(conditions[i][2]);
            }
            if(conditions[i][1] === '<'){
                return parseInt(state[conditions[i][0]]) < parseInt(conditions[i][2]);
            }
            if(conditions[i][1] === '>'){
                return parseInt(state[conditions[i][0]]) > parseInt(conditions[i][2]);
            }
        }
    }

    return (
        <div className="card shadow">
            <div className="card-header">
                <h2 className="card-title text-center m-0 p-0">{cuestionario.name}</h2>
            </div>
            <div className="card-body">
                <div className="d-flex flex-wrap justify-content-between">
                    {cuestionario.questions.map((question, key) => {
                        if(checkConditions(question.conditions, props.inputState)){
                            return <Pregunta key={question.cell} pregunta={question} errors={props.errors} onInputChange={props.onInputChange} value={props.inputState[question.cell]}/>
                        }
                    })}
                </div>
            </div>
            <div className="card-footer">
                <div className="d-flex justify-content-between">
                    <div>{props.index !== 0 && <button className="btn-dark btn" onClick={props.prevGroupCallback}>Anterior</button>}</div>
                    <div>{props.index !== (props.total - 1) && <div className="d-flex justify-content-end"><button className="btn-primary btn" onClick={props.nextGroupCallback}>Siguiente</button></div>}</div>
                    {props.index === (props.total - 1) && <div className="d-flex justify-content-end"><button className="btn-primary btn" onClick={props.finishQuestionsCallback}>Terminar</button></div>}
                </div>
            </div>
        </div>
    )
}

export default Grupo;