import {React, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Grupo from "./Grupo";
import Feedback from "./Feedback";
import {validateGroup} from "../validations";

const Cuestionario = () => {

    const [cuestionario, setCuestionario] = useState(window.cuestionario);
    const [isFeedback, setIsFeedback] = useState(false);
    const [groupIndex, setGroupIndex] = useState(0);

    const [inputState, setInputState] = useState(window.defaultValues);
    const [answers, setAnswers] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getAnswers();
    }, [])

    const onInputChange = (name, value) => {
        setInputState({...inputState, [name]: value});
    }

    const nextGroup = () => {
        const errors = validateGroup(groupIndex, inputState);
        if(!Object.keys(errors).length) {
            setErrors({});
            let index = groupIndex + 1;
            setGroupIndex(index);
        } else {
            setErrors(errors);
        }
    }

    const prevGroup = () => {
        let index = groupIndex - 1;
        setGroupIndex(index);
    }

    const getAnswers = async () => {
        const response = await axios.get('/cuestionario-respuestas');
        if(response.status === 200 && Object.keys(response.data).length){
            setAnswers(response.data);
            setIsFeedback(true);
        }
    }

    const finishQuestions = () => {
        // Save questions
        let formData = new FormData();
        formData.append('answers', JSON.stringify(inputState));
        axios.post('/cuestionario-respuestas', formData).then((res) => {
            setIsFeedback(true);
            setAnswers(res.data);
        })
            .catch((err) => {
                console.log(err);
            })
    }

    const progressPercentage = () => {
        return (groupIndex / cuestionario.length) * 100;
    }

    const resetForm = () => {
        setGroupIndex(0);
        setIsFeedback(false);
        setInputState(window.defaultValues);
        setAnswers([]);
    }

    return (
        <div>
            {!isFeedback &&
            <div className="progress my-2">
                <div className="progress-bar bg-info" role="progressbar" style={{width: progressPercentage() + '%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
            </div>
            }
            {!isFeedback && cuestionario.map((group, index) => {
                if (groupIndex === index) {
                    return <Grupo key={index}
                        index={index}
                        total={cuestionario.length}
                        cuestionario={cuestionario[index]}
                        inputState={inputState}
                        onInputChange={onInputChange}
                        nextGroupCallback={nextGroup}
                        prevGroupCallback={prevGroup}
                        finishQuestionsCallback={finishQuestions}
                        errors={errors}
                    />
                }
            })}
            {isFeedback && Object.keys(answers).length && <Feedback values={answers}/>}

            {isFeedback && <div className="btn btn-primary mt-2" onClick={resetForm}>Empezar de nuevo</div>}

        </div>
    )
}

export default Cuestionario;

if (document.getElementById('cuestionario-react')) {
    ReactDOM.render(<Cuestionario/>, document.getElementById('cuestionario-react'));
}