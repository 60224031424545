'use strict'

class FeedbackFunctions {

    constructor(values) {
        //this.values = this.fakeValues(values);
        this.values = this.parseValues(values);

        // Renta media por hogar matriz
        this.rentaMediaMatriz = [
            {
                miembros: 1,
                puntuaciones: {
                    1: 9625.2,
                    2: 12031.2,
                    3: 14437.3,
                    4: 17646.2,
                    5: 21656.7,
                    6: 25667.2
                }
            },
            {
                miembros: 2,
                puntuaciones: {
                    1: 16917,
                    2: 21146.25,
                    3: 25375.5,
                    4: 31014.5,
                    5: 38063.25,
                    6: 45112
                }
            },
            {
                miembros: 3,
                puntuaciones: {
                    1: 20780.4,
                    2: 25975.5,
                    3: 31170.6,
                    4: 38097.4,
                    5: 46755.9,
                    6: 55414.4
                }
            },
            {
                miembros: 4,
                puntuaciones: {
                    1: 23640.6,
                    2: 29550.75,
                    3: 35460.9,
                    4: 43341.1,
                    5: 53191.35,
                    6: 63041.6
                }
            },
            {
                miembros: 5,
                puntuaciones: {
                    1: 24951.6,
                    2: 31189.5,
                    3: 37427.4,
                    4: 45744.6,
                    5: 56141.1,
                    6: 66537.6
                }
            }
        ]
    }

    calificacionNivelIngresos() {
        let calification = 1;
        const nivelIngresos = this.nivelIngresos();
        const totalPersonas = this.totalPersonas() > 5 ? 5 : this.totalPersonas();
        let getMiembros = this.rentaMediaMatriz.filter((el) => (el.miembros === totalPersonas));

        if (getMiembros.length) {
            const puntuaciones = getMiembros[0].puntuaciones;
            for (let key of Object.keys(puntuaciones)) {
                if (parseFloat(nivelIngresos) >= puntuaciones[key]) {
                    calification = parseInt(key) + 1;
                }
            }
        }

        console.log("nivelIgresos: " + nivelIngresos, "totalPersonas:" + totalPersonas);

        return calification;
    }

    calificacionSeguridadIntresos(values) {
        return ((values['G21'] * 3.4)
            + (values['I24'] * 2.35)
            + (values['I25'] * 4.61)
            + (values['G26'] * 6.37)
            + (values['G27'] * 5.76))
            / (values['G21'] + values['I24'] + values['I25'] + values['G26'] + values['G27'] + values['G28']);
    }

    calificacionPotencialCrecimientoIngresos(values) {
        return ((values['G21'] * 4.4)
            + (values['I24'] * 3.21)
            + (values['I25'] * 4.09)
            + (values['G26'] * 3.42)
            + (values['G27'] * 2.33))
            / (values['G21'] + values['I24'] + values['I25'] + values['G26'] + values['G27']);
    }

    calificacionPotencialCrecimientoCorrecionEdad(calificacion, values) {
        const avgEdad = (values['I12'] + values['I13']) / 2;
        if (avgEdad < 30) {
            return calificacion * 1.29;
        }
        if (avgEdad < 45) {
            return calificacion * 1.17;
        }
        if (avgEdad < 65) {
            return calificacion * 1.023;
        }
        return calificacion;
    }

    scoreFinalNivelIngresos() {
        const calificacionNivelIngresos = this.calificacionNivelIngresos();
        const calificacionSeguridadIntresos = this.calificacionSeguridadIntresos(this.values);
        const calificacionPotencialCrecimientoIngresos = this.calificacionPotencialCrecimientoIngresos(this.values);
        const calificacionPotencialCrecimientoCorrecionEdad = this.calificacionPotencialCrecimientoCorrecionEdad(calificacionPotencialCrecimientoIngresos, this.values);

        console.log("calificacionNivelIngresos: " + calificacionNivelIngresos,
            "calificacionSeguridadIntresos: " + calificacionSeguridadIntresos,
            "calificacionPotencialCrecimientoIngresos: " + calificacionPotencialCrecimientoIngresos,
            "calificacionPotencialCrecimientoCorrecionEdad: " + calificacionPotencialCrecimientoCorrecionEdad);

        return ((((calificacionNivelIngresos * 10) / 7) * 0.2642)
            + (((calificacionSeguridadIntresos * 10) / 7) * 0.4920)
            + (((calificacionPotencialCrecimientoCorrecionEdad * 10) / 7) * 0.2437));

    }

    // Ratio deudas totales
    ratioDeudasTotales(values) {
        const nivelIngresos = this.nivelIngresos();
        const gastoHipotecaAnual = values['I48'] * 12;
        const totalDeudas = this.calculoDeudas();

        return (gastoHipotecaAnual + totalDeudas) / nivelIngresos;

    }

    // Ratio deudas excluido vivienda
    ratioDeudasExcluidoVivienda() {
        console.log("ratioDeudasExcluidoVivienda: " + this.calculoDeudas() / this.nivelIngresos());
        return this.calculoDeudas() / this.nivelIngresos();
    }

    // Porcentaje gasto prestamos personales
    porcentajeGastoPrestamos() {
        const porcentajeTotalIngresos = (this.values['I131'] * 12) / this.nivelIngresos();
        return (porcentajeTotalIngresos / this.ratioDeudasExcluidoVivienda());
    }

    // Porcentaje gasto creditos rapidos
    porcentajeGastoCreditos() {
        const porcentajeTotalIngresos = (this.values['I134'] * 12) / this.nivelIngresos();
        return (porcentajeTotalIngresos / this.ratioDeudasExcluidoVivienda());
    }

    // Porcentaje gasto tarjetas de credito
    porcentajeGastoTarjetasCredito() {
        const porcentajeTotalIngresos = (this.values['I137'] * 12) / this.nivelIngresos();
        return (porcentajeTotalIngresos / this.ratioDeudasExcluidoVivienda());
    }

    // Porcentaje gasto descubiertos cuenta
    porcentajeGastoDescubiertos() {
        const porcentajeTotalIngresos = (this.values['I140'] * 12) / this.nivelIngresos();
        return (porcentajeTotalIngresos / this.ratioDeudasExcluidoVivienda());
    }

    // Ratio dinero destinado a la vivienda
    ratioDineroDestinadoVivienda(values) {
        const ratio = ((values['I48'] * 12) + (values['I51'] * 12) + values['I54'] + (values['I57'] * 12) + values['I60']) / this.nivelIngresos();
        console.log('ratioDineroDestinadoVivienda', ratio);
        return ratio;
    }

    calculoDeudas() {
        return (this.values['I131'] * 12) + (this.values['I134'] * 12) + (this.values['I137'] * 12) + (this.values['I140'] * 12);
    }

    calculoGastosDiscrecionales() {
        return (this.values['G108'] * 12) + (this.values['G111'] * 12) + (this.values['G114'] * 12) + (this.values['G117'] * 12) + this.values['G120'] + (this.values['G123'] * 12) + (this.values['G126'] * 12);
    }

    ratioAhorro() {
        return ((this.values['I105'] * 12) + this.values['I102']) / this.nivelIngresos();
    }

    // Suma de ingresos
    nivelIngresos() {
        return this.values['G33'] + this.values['G34'] + this.values['G35'];
    }

    // Total personas en hogar. Si son mas de 7, poner 7
    totalPersonas() {
        const total = this.values['G10'] + this.values['G14'] + this.values['G15'] + this.values['G16'];
        return total > 7 ? 7 : total;
    }

    // Conoce el gasto fijo obligatorio-vivienda
    conoceGastoFijoVivienda(values) {
        const posiblesPreguntas = values['G47'] + values['G50'] + values['G53'] + values['G56'] + values['G59'];
        const nsNcPreguntas = (posiblesPreguntas - (values['G47'] + values['G50'] + (values['I54'] > 0 ? 1 : 0) + (values['I57'] > 0 ? 1 : 0) + (values['I60'] > 0 ? 1 : 0)));
        return nsNcPreguntas / posiblesPreguntas;
    }

    // Conoce otros gastos fijos
    conoceOtrosGastosFijos(values) {
        const posiblesPreguntas = values['G94'] + values['G90'] + values['G97'] + values['G87'];
        const nsNcPreguntas = (posiblesPreguntas - ((values['I95'] > 0 ? 1 : 0) + (values['I91'] > 0 ? 1 : 0) + (values['I98'] > 0 ? 1 : 0) + (values['I88'] > 0 ? 1 : 0)));
        return nsNcPreguntas / posiblesPreguntas;
    }

    // Conoce gasto variable necesario
    conoceGastoVariableNecesario(values) {
        const posiblesPreguntas = 4 + values['G75'] + values['G82'] + values['G78'];
        const nsNcPreguntas = (posiblesPreguntas - (1 + values['G66'] + values['G69'] + values['G72'] + (values['I76'] > 0 ? 1 : 0) + ((values['I83'] + values['I84'] + values['I85']) > 0 ? 1 : 0) + (values['I79'] > 0 ? 1 : 0)));
        return nsNcPreguntas / posiblesPreguntas;
    }

    // Convierte los valores a integer o 0 si son null
    parseValues(values) {
        for (let key of Object.keys(values)) {
            values[key] = parseInt((values[key] === null || !values[key]) ? 0 : values[key]);
        }
        return values;
    }

    /* Outpus ------------------------------------------ */

    // 1 Más de la mitad de los hogares españoles tiene algún tipo de deuda, así que no hay problema por tener deudas, sólo hay que saber gestionarlas bien.
    output1TieneDeuda() {
        if (this.calculoDeudas() > 0) {
            return {
                text: "Más de la mitad de los hogares españoles tiene algún tipo de deuda, así que no hay problema por tener deudas, sólo hay que saber gestionarlas bien.",
                classes: 'info',
                score: this.calculoDeudas()
            }
        }
    }

    // 2 Un primer indicador positivo es que tu nivel de deuda (incluida hipoteca) no supera el 40% recomendado sobre los ingresos netos
    output2NivelDeuda() {
        const ratioDeudasTotales = this.ratioDeudasTotales(this.values);
        console.log("ratioDeudasTotales", ratioDeudasTotales);
        if (ratioDeudasTotales >= 0.40) {
            return {
                text: "Tu nivel total de deuda (incluida hipoteca) supera el 40% recomendado sobre los ingresos netos",
                score: ratioDeudasTotales
            }
        }
        return {
            text: "Un primer indicador positivo es que tu nivel de deuda (incluida hipoteca) no supera el 40% recomendado sobre los ingresos netos",
            score: ratioDeudasTotales
        }
    }

    // 3 Tu nivel de endeudamiento (sin tener en cuenta hipoteca) es adecuado al nivel de ingresos, además estás utilizando fuentes de financiación apropiadas
    output3NivelEndeudamiento() {

        let score = 0;

        // Get tipo de deuda
        const gastos = [this.porcentajeGastoPrestamos(), this.porcentajeGastoCreditos(), this.porcentajeGastoTarjetasCredito(), this.porcentajeGastoDescubiertos()];
        const maxTipoGasto = gastos.indexOf(Math.max(...gastos));

        // Get deuda sobre ingreso neto
        let porcentageDeudaSobreIngresoNeto = 1;
        const ratioDeudasExcluidoVivienda = this.ratioDeudasExcluidoVivienda();

        if (ratioDeudasExcluidoVivienda < 0.05) {
            porcentageDeudaSobreIngresoNeto = 1;
        } else if (ratioDeudasExcluidoVivienda < 0.1) {
            porcentageDeudaSobreIngresoNeto = 2;
        } else if (ratioDeudasExcluidoVivienda < 0.2) {
            porcentageDeudaSobreIngresoNeto = 3;
        } else if (ratioDeudasExcluidoVivienda < 0.3) {
            porcentageDeudaSobreIngresoNeto = 4;
        } else {
            porcentageDeudaSobreIngresoNeto = 5;
        }

        // D. Prestamos personales
        if (maxTipoGasto === 0) {
            if ([1, 2].includes(porcentageDeudaSobreIngresoNeto)) {
                score = 4;
            } else if ([3, 4].includes(porcentageDeudaSobreIngresoNeto)) {
                score = 3;
            } else {
                score = 2;
            }
        } else if (maxTipoGasto === 1) { // C. Creditos personales
            if (porcentageDeudaSobreIngresoNeto === 1) {
                score = 4;
            } else if (porcentageDeudaSobreIngresoNeto === 2) {
                score = 3;
            } else if ([3, 4].includes(porcentageDeudaSobreIngresoNeto)) {
                score = 2;
            } else {
                score = 1;
            }
        } else if (maxTipoGasto === 2) { // B-Pagos aplazados con tarjetas de crédito
            if ([1, 2].includes(porcentageDeudaSobreIngresoNeto)) {
                score = 3;
            } else if ([3, 4].includes(porcentageDeudaSobreIngresoNeto)) {
                score = 2;
            } else {
                score = 1;
            }
        } else if (maxTipoGasto === 3) { // A-Descubiertos en cuenta- Deuda en instrumentos nada adecuados
            if (porcentageDeudaSobreIngresoNeto === 1) {
                score = 3;
            } else if ([2, 3].includes(porcentageDeudaSobreIngresoNeto)) {
                score = 2;
            } else {
                score = 1;
            }
        }

        switch (score) {
            case 4:
                return {
                    text: "Tu nivel de endeudamiento (sin tener en cuenta hipoteca) es adecuado al nivel de ingresos, además estás utilizando fuentes de financiación apropiadas",
                    classes: "success",
                    score: score
                }
            case 3:
                return {
                    text: "No debes descuidar el nivel de tus deudas (sin tener en cuenta hipoteca) siendo especialmente cauto en los instrumentos a través de los cuales te vas a endeudar",
                    classes: "warning",
                    score: score
                }
            case 2:
                return {
                    text: "El nivel de deuda (sin tener en cuenta hipoteca) está llegando a un límite de cierto peligro",
                    classes: "danger",
                    score: score
                }
            case 2:
                return {
                    text: "La situación es muy delicada ya que la proporción de deudas (sin tener en cuenta hipoteca) es muy alta y en instrumentos cuyo coste disparará dicha deuda",
                    classes: "danger",
                    score: score
                }
        }

        return null;
    }


// 4 El nivel de ingresos del hogar es muy adecuado
    output4NivelIngresosHogar() {
        const scoreFinalNivelIngresos = this.scoreFinalNivelIngresos();

        console.log("scoreFinalNivelIngresos: ", scoreFinalNivelIngresos);

        if (scoreFinalNivelIngresos >= 5.72) {
            return {
                text: "El nivel de ingresos del hogar es muy adecuado",
                classes: 'success',
                score: scoreFinalNivelIngresos
            }
        }
        if (scoreFinalNivelIngresos >= 4.29) {
            return {
                text: "El nivel de ingresos coincide con la media española de acuerdo al número de miembros del hogar",
                classes: 'success',
                score: scoreFinalNivelIngresos
            }
        }
        if (scoreFinalNivelIngresos >= 2.86) {
            return {
                text: "El nivel de ingresos está algo por debajo de la media española de acuerdo al número de miembros del hogar",
                classes: 'warning',
                score: scoreFinalNivelIngresos
            }
        }
        if (scoreFinalNivelIngresos >= 1.43) {
            return {
                text: "El nivel de ingresos es bastante inferior a la media española de acuerdo al número de miembros del hogar",
                classes: 'danger',
                score: scoreFinalNivelIngresos
            }
        }
        return {
            text: "El nivel de ingresos es bastante inferior a la media española de acuerdo al número de miembros del hogar",
            classes: 'danger',
            score: scoreFinalNivelIngresos
        }
    }


// 5 Ojo, dedicas un " &Datos!D405& " de gasto dedicado a la vivienda mientras que el Banco de España recomienda que no se supere el 30%
    output5GastoVivienda() {
        const ratioDineroDestinadoVivienda = this.ratioDineroDestinadoVivienda(this.values);
        if (ratioDineroDestinadoVivienda > 0.3) {
            return {
                text: `Ojo, dedicas un ${Number(ratioDineroDestinadoVivienda).toFixed(2)}% de gasto dedicado a la vivienda mientras que el Banco de España recomienda que no se supere el 30%`,
                classes: "danger"
            }
        }
    }

// 6 Según tus respuestas se detecta cierto esfuerzo para ahorrar, sería importante controlar esas cuantías y que fueran de al menos el 10% de tus ingresos netos
    output6EsfuerzoParaAhorrar() {
        if (this.values['G101'] === 1 && this.values['I105'] <= 0) {
            return {
                text: "Según tus respuestas se detecta cierto esfuerzo para ahorrar, sería importante controlar esas cuantías y que fueran de al menos el 10% de tus ingresos netos",
                classes: "info"
            }
        }
    }

// 7 En tu hogar se ahorra un " &Datos!I389&", pero el Banco de España recomienda que sea al menos el 10% del ingreso neto
    output7NivelDeAhorro() {
        const ratioAhorro = this.ratioAhorro();
        console.log("ratioAhorro: " + ratioAhorro);
        if (ratioAhorro < 0.1) {
            return {
                text: `En tu hogar se ahorra un ${ratioAhorro.toFixed(2)}%, pero el Banco de España recomienda que sea al menos el 10% del ingreso neto`,
                classes: 'info'
            }
        }
    }

// 8 Tu nivel de ahorro es adecuado y coincide con tu percepción sobre tu capacidad de ahorro, ¡enhorabuena!
    output8NivelAhorroAdecuado() {
        if (this.ratioAhorro() > 0.1 && this.values['G38'] >= 3) {
            return {
                text: "Tu nivel de ahorro es adecuado y coincide con tu percepción sobre tu capacidad de ahorro, ¡enhorabuena!",
                classes: "success"
            }
        }
    }

    // 9 Tu percepción del ahorro mensual no corresponde con tu capacidad de ahorro real
    output9PercepcionAhorro() {
        if (this.ratioAhorro() <= 0.1 && this.values['G38'] >= 3) {
            return {
                text: "Tu percepción del ahorro mensual no corresponde con tu capacidad de ahorro real",
                classes: "warning"
            }
        }
    }

    // 10 Sería altamente recomendable conocer mejor los gastos fijos en vivienda (hipoteca/alquiler, gastos de comunidad, seguro, IBI)
    output10RecomendacionConocerGastosVivienda() {
        const ratioVivienda = this.conoceGastoFijoVivienda(this.values);
        const ratioOtrosGastos = this.conoceOtrosGastosFijos(this.values);
        const ratioVariable = this.conoceGastoVariableNecesario(this.values);

        console.log("ratioVivienda: " + ratioVivienda, "ratioOtrosGastos: " + ratioOtrosGastos, "ratioVariable: " + ratioVariable);

        if (ratioVivienda > 0.25) {
            return {
                text: "Sería altamente recomendable conocer mejor los gastos fijos en vivienda (hipoteca/alquiler, gastos de comunidad, seguro, IBI)",
                classes: "info",
                ratioVariable: ratioVariable,
                ratioOtrosGastos: ratioOtrosGastos
            }
        }
    }

    // 11 Sería recomendable controlar tus gastos (suministros, transporte, otros gastos…) 
    output11RecomendacionConocerGastosOtros(ratioOtrosGastos, ratioVariable) {
        if (ratioOtrosGastos > 0.5 || ratioVariable > 0.5) {
            return {
                text: "Sería recomendable controlar tus gastos (suministros, transporte, otros gastos…) ",
                classes: "info"
            }
        }
    }

    // 12 Existen indicios de que tienes una percepción más optimista  del nivel de endeudamiento del hogar que la real
    output12IndiciosPercepcionOptimista() {
        const message = {
            text: "Existen indicios de que tienes una percepción más optimista  del nivel de endeudamiento del hogar que la real",
            classes: "info"
        }
        const ratioDeudasTotales = this.ratioDeudasTotales(this.values);
        if (ratioDeudasTotales > 0.6) {
            if (this.values['G163'] > 1) {
                return message;
            }
        }
        if (ratioDeudasTotales > 0.4) {
            if (this.values['G163'] > 2) {
                return message;
            }
        }
        if (ratioDeudasTotales > 0.2) {
            if (this.values['G163'] === 4) {
                return message;
            }
        }
    }

    // 13 Muy bien, es positivo documentarse en varias fuentes de información sobre las alternativas de financiación
    output13DocumentarseFinanciacion() {
        const sumaFuentesInformacion = this.values['I150'] + this.values['I151'] + this.values['I152'] + this.values['I153'];
        if (sumaFuentesInformacion >= 2 && this.values['I153'] === 1) {
            return {
                text: "Muy bien, es positivo documentarse en varias fuentes de información sobre las alternativas de financiación",
                classes: "success"
            }
        }
    }

    // 14 Considera consultar condiciones de financiación en entidades financieras (oficinas/online)
    output14ConsideraConsultarCondidicones() {
        // si Entidades financieras (oficina/banca online) == 0
        return this.values['I153'] === 0 ? {
            text: "Considera consultar condiciones de financiación en entidades financieras (oficinas/online)",
            classes: "info",
            score: 0
        } : null;
    }

    // 15 Es fundamental conocer el coste de la deuda para una apropiada gestión del endeudamiento de tu hogar
    output15ConocerCosteDeuda() {
        const conocimientoCosteDeuda = this.values['G155'];
        return (1 * conocimientoCosteDeuda) < 3 ? {
            text: "Es fundamental conocer el coste de la deuda para una apropiada gestión del endeudamiento de tu hogar",
            classes: "info",
            score: conocimientoCosteDeuda
        } : null;
    }

    // 16 Enhorabuena, controlas bien la situación de gastos de tu hogar y buscas de manera adecuada los métodos de financiación conociendo su coste
    output16ControlasBienSituacionGastos(ratioDeudasTotales, nivelTotalDeuda) {
        // =SI(Y(B54="";B55>2;B73="";B72="");A77;"")
        return (ratioDeudasTotales < 0.4)
            && (nivelTotalDeuda > 2)
            && {
                text: "Enhorabuena, controlas bien la situación de gastos de tu hogar y buscas de manera adecuada los métodos de financiación conociendo su coste",
                classes: "Success"
            }

    }

    // 17 ¿Por qué no comenzar ya? Algunos tutoriales y dónde aprender más sobre el coste de la deuda
    output17PorqueNoComenzarYa() {
        return {
            text: "¿Por qué no comenzar ya? Algunos tutoriales y dónde aprender más sobre el coste de la deuda",
            classes: "info"
        }
    }

    // 18 Quizás sería adecuado valorar otras alternativas de financiación antes de aceptar las condiciones ofrecidas en el momento de la compra
    output18OtrasAlternativasFinanciacion(ratioDeudasTotales, scoreNivelEndeudamiento) {
        console.log("scoreOtrasAlternativasFinanciacion", this.values['G142'], ratioDeudasTotales, scoreNivelEndeudamiento);
        if (this.values['G142'] === 3) {
            if (ratioDeudasTotales < 0.4 && scoreNivelEndeudamiento === 4) {
                return {
                    text: "Quizás sería adecuado valorar otras alternativas de financiación antes de aceptar las condiciones ofrecidas en el momento de la compra",
                    classes: "info"
                }
            }
            if (scoreNivelEndeudamiento < 4) {
                return {
                    text: "Quizás sería adecuado valorar la necesidad de bienes y servicios antes de endeudarse o buscar fuentes de financiación más adecuadas",
                    classes: "info"
                }
            }
        }
    }

    // 19 En caso de situación complicada se recomienda revisar los gastos discrecionales. Tus gastos suponen
    output19RevisarGastosDiscrecionales(ratioDeudasTotales) {
        console.log('ratiodeudastotales', ratioDeudasTotales);
        if (ratioDeudasTotales < 0.4) {
            return null;
        }
        const calculoGastosDiscrecionales = this.calculoGastosDiscrecionales();
        const ratio = calculoGastosDiscrecionales / this.nivelIngresos();
        const restaurantes = this.values['G108'];
        const alcohol = this.values['G111'];
        const cines = this.values['G114'];
        const gimnasios = this.values['G117'];
        const vacaciones = this.values['G120'];
        const juegos = this.values['G123'];
        return ratioDeudasTotales !== null && {
            text: `En caso de situación complicada se recomienda revisar los gastos discrecionales. Tus gastos suponen un ${ratio.toFixed(2)} de tus ingresos. 
            Puedes valorar recortar en... 
            ${restaurantes ? " Restaurantes, Bares, Cafés, " : ''}
            ${alcohol ? " Alchohol/Tabaco, " : ''}
            ${cines ? " Cines, Teatros, Conciertos, " : ''}
            ${gimnasios ? " Gimnasio, Deportes, " : ''}
            ${vacaciones ? " Vacaciones y Regalos, " : ''}
            ${juegos ? " Loterías y otros juegos de azar, " : ''}
             por ejemplo.`
        }

    }

    fakeValues(values) {
        values['G10'] = "2"; // Individuo
        values['G14'] = "1"; // Ascendientes
        values['G15'] = "1"; // Descendientes
        values['G33'] = "18000"; // Renta
        values['G34'] = "600"; // Pensiones
        values['G21'] = "1"; // cuenta propia
        values['G26'] = "1"; // Funcionario
        values['G27'] = "1"; // Pensionista
        values['I24'] = "1"; // Ajena temporal
        values['I12'] = "65"; // Ajena temporal
        values['I13'] = "70"; // Ajena temporal
        values['G38'] = "5"; // ¿Cuál estima que es su capacidad de ahorro mensual ?

        values['G47'] = "1"; // Tiene que pagar hipoteca
        values['I48'] = "800" // Hipoteca
        values['G50'] = "1"; // Tiene que pagar alquiler
        values['I51'] = "300" // Alquiler
        values['G53'] = "1"; // Tiene que pagar seguro vivienda
        values['I54'] = "0"; // Seguro vivienda
        values['G56'] = "1"; // Tiene que pagar gastos comunidad
        values['I57'] = "0"; // Gastos comunidad mensual

        values['I64'] = '100'; // Alimentacion mensual
        values['G66'] = "0"; // Conoce gasto electricidad
        values['I67'] = '0'; // Electricidad mensual
        values['G69'] = "0"; // Coonoce gasto telefono
        values['I70'] = '0'; // Telefono
        values['G72'] = "0"; // Conoce gasto ropa
        values['I73'] = '0'; // Ropa
        values['G75'] = "1" // Conoce gasto education
        values['I76'] = '50'; // Education
        values['G78'] = "1"; // Conoce gastos mascotas
        values['I79'] = "0"; // Gasto mascotas

        values['G82'] = "1"; // Conoce gastos transprote
        values['I83'] = '80'; // Gasolina, transporte..
        values['G87'] = "1"; // Tiene vehiculo propio
        values['I88'] = '100'; // Impuestos circulacion
        values['G90'] = "1"; // Sabe gasto seguro coche
        values['I91'] = '250'; // Seguro coche

        values['G94'] = "1"; // Realiza aportaciones a instrumentos de ahorro
        values['I102'] = '200'; // Seguro de pension, aportaciones
        values['I105'] = '20'; // Reserva mensual para urgencias

        values['G108'] = '150'; // Gasto restaurantes
        values['G109'] = '1'; // Puede disminuir
        values['G111'] = "200"; // Gasto alchol
        values['G112'] = '0'; // Puede disminuir

        values['G130'] = "1"; // Tiene contratado algun prestamo personal
        values['I131'] = '600'; // Pago mensual prestamos personal
        values['G133'] = '1'; // Tiene credito rapido
        values['I134'] = "600"; // Pago mensual credito
        values['G142'] = "3"; //Cuando desea adquirir algún bien o servicio que no puede pagar al contado…


        // ¿Qué fuentes de información utiliza para buscar financiación?
        values['I150'] = "1";
        values['I151'] = "1";
        values['I152'] = "1";
        //values['I153'] = "1";

        values['G155'] = "1"; //¿Conoce el coste que le suponen las deudas que tiene?
        values['G163'] = "4"; //¿Con cuál de las siguientes afirmaciones identificaría su situación de endeudamiento?

        return values;
    }
}

export default FeedbackFunctions;